var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-img', {
    attrs: {
      "height": _vm.maxHeight,
      "max-height": _vm.maxHeight,
      "width": _vm.width ? _vm.width : '',
      "contain": "",
      "eager": "",
      "src": _vm.src
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }